(function ($, Drupal) {

        /* Expand filter bar if the user had it open earlier. If on mobile, always collapse it */
        function expand_sidebar_filter() {
            var expand_sidebar = (localStorage.getItem('search-expanded')) && ($(window).width() > $mobileBreakpoint);

            if (expand_sidebar) {
                /* Expand the left sidebar */
                $('.view-header').find('.search-options').hide();
                $('.research-landing-results').addClass('has-filters has-filters-mobile');
                $('.layout__region--filter-bar').addClass('has-filters has-filters-mobile');
            } else {
                if ($(window).width() <= $mobileBreakpoint) {
                    /* Collapse the left sidebar */
                    $('.research-landing-results').removeClass('has-filters has-filters-mobile');
                    $('.layout__region--filter-bar').removeClass('has-filters has-filters-mobile');
                    $('.open-search-options').show().css("display", "inline-block");
                    $('.close-search-options').hide();

                }
            }
        }


        // clicking an area filter link in top section
        function area_click() {
            $('.area-filters').on('click', '> li', function() {
                var tab = $(this);

                if (!tab.hasClass('active')) {
                    // toggle desired tab
                    tab.addClass('active');
                    tab.siblings('li').removeClass('active');

                    // toggle desired content
                    $('.block-views-blockresearch-and-impact-areas-block-1').toggle(tab.hasClass('areas-research'));
                    $('.block-views-blockresearch-and-impact-areas-block-2').toggle(!tab.hasClass('areas-research'));
                }
            });
        }

        function mobile_area_filter_toggle() {
            if($('.area-filters').length) {
                $('.area-filter-item').each(function() {
                    $(this).find('a').after('<span class="js-info-toggle">i</span>');
                });
                $('.area-filter-item .js-info-toggle').once().click(function() {
                    $(this).parent().find('.blurb').toggleClass('open');
                });
            }
        }

        // Select the research type to filter on
        function research_type_click() {
            $('ul.research-type-list a, .view-empty a[data-research-type]').click(function() {
                var t = $(this).data('research-type');
                select_research_type(t);
            });
        }

        // Select a research type to be displayed
        function select_research_type(t) {
            if ($("body").is(".path-research")) {
                $(".research-results").hide();
                $("." + t + "-results").show();
                $(".block-facet-blockrole-for-and").toggle(t == 'people');
                $(".block-facet-blockmedia-for-and").toggle(t == 'news');
                $(".block-facet-blockopportunities-available").toggle((t == 'projects') || (t == 'groups'));

                // Update all the facet links to include the selected research type, so
                // the type will be preserved when the facet changes
                jQuery(".facets-checkbox").each(function (i, e) {
                    var current_link = $(e).data('facetsredir');
                    var new_link = current_link;
                    var char_index = current_link.indexOf('ctype');
                    if (char_index != -1) {
                        new_link = current_link.substring(0, char_index - 1);
                    }
                    if (new_link .indexOf("?") === -1) {
                        new_link += "?ctype=" + t;
                    } else {
                        new_link += "&ctype=" + t;
                    }

                    $(e).data('facetsredir', new_link);
                });

                // Set active status on the research type links
                $('ul.research-type-list a').removeClass('is-active');
                $('ul.research-type-list a[data-research-type=' + t +']').addClass('is-active');
            }
        }

        // Set the research type based on the value from the URL
        function set_research_type_from_url() {
            var q = window.location.search;
            var char_index = q.indexOf('ctype=');
            var t = "groups"; // default
            if (char_index != -1) {
                char_index += "ctype=".length;
                var end_index = q.indexOf('&', char_index + 1);
                if (end_index == -1) {
                    t = q.substring(char_index);
                } else {
                    t = q.substring(char_index, end_index);
                }
            }
            select_research_type(t);
        }

        // Set the the selected research area/impact area based on the value from the URL
        function set_selected_area_from_url() {
            var q = window.location.search;
            var h = "/research" + q;
            $('.block-views-blockresearch-and-impact-areas-block-1 a').each(function() {
                if (h.startsWith($(this).attr('href'))) {
                    $(this).addClass('selected');
                    $('.area-filters li.areas-research').addClass('active');
                    $('.area-filters li.areas-impact').removeClass('active');
                    $('.block-views-blockresearch-and-impact-areas-block-1').show();
                    $('.block-views-blockresearch-and-impact-areas-block-2').hide();
                }
            });
            $('.block-views-blockresearch-and-impact-areas-block-2 a').each(function() {
                if (h.startsWith($(this).attr('href'))) {
                    $(this).addClass('selected');
                    $('.area-filters li.areas-research').removeClass('active');
                    $('.area-filters li.areas-impact').addClass('active');
                    $('.block-views-blockresearch-and-impact-areas-block-1').hide();
                    $('.block-views-blockresearch-and-impact-areas-block-2').show();
                }
            });
        }

        // clicking search-options button
        function search_options_click() {

            $('body').on('click', '.open-search-options', function(e) {
                // fly out panel for filters
                $('.layout__region--sidebar-filters').addClass('has-transitions');
                $('.research-landing-results').addClass('has-filters has-filters-mobile');
                $('.layout__region--filter-bar').addClass('has-filters has-filters-mobile');
                $('.open-search-options').hide();
                $('.close-search-options').show().css("display", "inline-block");

                if($(window).width() < $mobileBreakpoint) {
                    $('html, body').addClass('scroll-lock');

                } else {
                    scroll_to_content();
                }

                localStorage.setItem('search-expanded',1);
            });

        }

        // click to close search-options
        function close_search_options() {
            $('body').on('click', '.close-search-options', function() {
                $('.layout__region--sidebar-filters').addClass('has-transitions');
                $('.research-landing-results').removeClass('has-filters has-fiters-mobile');
                $('.layout__region--filter-bar').removeClass('has-filters has-fiters-mobile');
                $('.open-search-options').show().css("display", "inline-block");
                $('.close-search-options').hide();
                $('html, body').removeClass('scroll-lock');
                localStorage.removeItem('search-expanded');
            });
        }

        // in sidebar-filters, add toggle to research-area facet filter items with children
        function sidebar_facet_parent_item_toggle() {
            $('.block-region-sidebar-filters .facet-item--expanded:not(.closed, .open)').each(function() {
                var li = $(this);
                if (li.hasClass('facet-item--active-trail')) {
                    li.addClass('open');
                    li.prepend('<i class="facet-parent-toggle-trigger open"></i>');
                }
                else {
                    li.addClass('closed');
                    li.prepend('<i class="facet-parent-toggle-trigger"></i>');
                }
            });
            $('.facet-parent-toggle-trigger').off().on('click', function(e) {
                var trigger = $(this);
                trigger.toggleClass('open');
                trigger.closest('.facet-item--expanded').toggleClass('closed open');
                e.stopPropagation();
            });
        }

        // add active facet filters to breadcrumb section
        function active_facet_breadcrumb() {
            $(window).on('load', function() {
                if ($('.block-region-sidebar-filters .facet-item input[type="checkbox"]:checked').length) {
                    if ($('body.path-people').length) {
                        $('.research-results .view-header').after('<ul class="research-active-breadcrumb" />');
                    } else {
                        $('.research-results').prepend('<ul class="research-active-breadcrumb" />');
                    }
                    $('.block-region-sidebar-filters .facet-item input[type="checkbox"]:checked').each(function () {
                        var activeItem = $(this).next('label').clone().wrap('<div />').parent().html();
                        $('.research-active-breadcrumb').append('<li>' + activeItem + '</li>');
                    });
                }
            });
        }

        // add placeholder for research search text input
        function research_search_placeholder() {
            $('#views-exposed-form-research-search-page-1 #edit-keywords').attr('placeholder', 'Search research');
            $('#views-exposed-form-person-search-page-1 #edit-keywords').attr('placeholder', 'Search people');
            $('#views-exposed-form-events-search-page-1 #edit-keywords').attr('placeholder', 'Search');
            $('#views-exposed-form-news-landing-page-page-1 #edit-keywords').attr('placeholder', 'Search news');
            $('#views-exposed-form-csail-search-page-1 #edit-keywords').attr('placeholder', 'Search');
        }

        function research_results_count(research_type_list) {
            $.each(research_type_list, function (i, e) {
                var selector = "." + e + "-results";
                if ($(selector).filter(".and-results").find(".research-results-count").length || $(selector).filter(".or-results").find(".research-results-count").length) {
                    /* If there are different results for the AND and OR query, display each of them individually.
                       Otherwise, collapse them into a single count */
                    var and_result_section = $(selector).filter(".and-results").find(".research-results-count");
                    var or_result_section = $(selector).filter(".or-results").find(".research-results-count");
                    var and_result_count = and_result_section.data('count');
                    var or_result_count = or_result_section.data('count');

                    /* Change language if only one result */
                    if (and_result_count == 1) {
                        and_result_section.text(and_result_section.text().replace("Results", "Result"));
                    }
                    if (or_result_count == 1) {
                        or_result_section.text(or_result_section.text().replace("Results", "Result"));
                    }

                    if (and_result_count == or_result_count) {
                        $(selector).filter(".and-results").remove();
                    } else {
                        var combined_header = $(selector).filter('.and-results').find('.view-header');
                        $(selector).filter('.or-results').find('.research-results-count').prependTo(combined_header);
                        combined_header.addClass('merged-counts');
                        if (and_result_count > 0) {
                            $(selector).filter('.or-results').addClass('hide-breadcrumb');
                        }
                    }

                    /* Update counts for the navigation bar */
                    $('ul.research-type-list a[data-research-type=' + e +'] span.research-count').remove();
                    if (window.location.search) {
                        var nav_result_count = or_result_count ? or_result_count : and_result_count;
                        $('ul.research-type-list a[data-research-type=' + e +']').append("<span class='research-count' >(" + nav_result_count + ")</span>");
                    } else {
                        $('ul.research-type-list a[data-research-type=' + e +']').append("<span class='empty research-count'></span>");
                    }
                } else {
                    $('ul.research-type-list a[data-research-type=' + e +'] span.research-count').addClass('empty');
                }
            });
        }

        // Display overlay when facet item clicked
	// disabling as part of debugging by remaming element
        function facet_item_click() {
            $('li.facet-item-OFF').on('click', function(e) {
                $('body').addClass('loading');
                // $(this).find('input').click();
            });
        }

        // reset filters
        function reset_filters() {
            $('body').on('click', '.reset-filters', function() {
                var baseUrl = window.location.pathname;
                window.location = baseUrl;
            });
        }

        function display_results() {
            if($('.layout--research-landing').length && window.location.search != '') {
                $('.total-results').show();
            }
        }

        /* If coming to this page with search terms, scroll to the top of the content */
        function scroll_to_content_on_load() {
            if (($("body").is(".path-research") || $("body").is(".path-news")) && (window.location.search != '')) {
                scroll_to_content();
            }
        }

        /* Scroll to the top of the content, but only on the Research page, because is doesn't make sense
         * on other pages */
        function scroll_to_content() {
            if ($("body").is(".path-research") || $("body").is(".path-news")) {
                $('html, body').animate({
                    scrollTop: ($('.research-landing-results').offset().top - 60)
                }, 500);
            }
        }

        /* Expand the role facet on the person page, so people can see the full hierarchy at all times */
        function person_search_expand_facets() {
            $('.block-facet-blockperson-search-role .facet-item--expanded').removeClass('closed').addClass('open');
        }

        if ($('.layout--research-landing').length) {
            var research_type_list = ['groups', 'news', 'people', 'projects', 'cors'];
            // run all these functions

            /* Expand filter bar if the user had it open earlier */
            expand_sidebar_filter();

            /* Select whether to display impact areas or research areas at the top of the page, based
             on whether a research area or impact area has been selected
             */
            set_selected_area_from_url();

            /* Count the "And" and "Or" results for each type of result on the research page, and insert
             text with the counts
             */
            research_results_count(research_type_list);

            /* Scroll to the content area of the page if there's a search query, or if they just used a facet */
            scroll_to_content_on_load();

            /* Add icons after each area filter for more information, and attach click handlers */
            mobile_area_filter_toggle();

            /* If any facets options are selected, add the links that display above the results to
               show the options, and allow them to be unselected
               TODO: Should we close the ul tag?
              */
            active_facet_breadcrumb();

            /* Select the tab to show, based on the URL (People, News, Projects, Groups */
            set_research_type_from_url();

            /* Click handler to expand the search sidebar */
            search_options_click();

            /* Click handler to select the tab to display (People, News, Projects, Groups) */
            research_type_click();

            /* Click handler for the link to close the search sidebar */
            close_search_options();

            /* Toggle between impact areas and research areas at the top of the page */
            area_click();

            /* Click handler for reset filters link */
            reset_filters();

            /* Toggle display, and add click handler, for nested facets */
            sidebar_facet_parent_item_toggle();

            /* Add placeholder text for search terms */
            research_search_placeholder();

            /* Display the number of results, if any kind of a search was applied */
            display_results();

            /* If on the person page, expand the facets. TODO: Expand by default, and/or do this earlier */
            person_search_expand_facets();

            /* Click handler - display loading overlay when a facet item is selected */
            facet_item_click();

        }


})(jQuery, Drupal);
